.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  z-index: 10;

  > .inside {
    @include container();
    height: 0;
    padding-bottom: 40%;
  }

  .headline,
  .subheadline {
    @include font(36, 43);
    margin: 0;
    padding: 0;
  }

  .headline {
    color: $font-color-invert;
  }

  .subheadline {
    color: $font-color-base;
  }

  .text_container {
    @include persicon-gradient(20deg, $color-blue-alpha, $color-blue2-alpha);
    @include brackets();
    @include span(14 of 24);
    @include pre(5 of 24);
    position: relative;
    margin-top: 10vw;
    //margin-bottom: 12.5vw;
    padding: 36px 40px ($baseline * 3) 40px;

    &:before {
      top: -35px;
      right: -35px;
    }

    &:after {
      bottom: -35px;
      left: -35px;
    }

    a.readmore {
      position: absolute;
      right: 25px;
      bottom: 15px;
      color: $font-color-invert;
      text-decoration: none;

      &:hover {
        color: lighten($font-color-invert, 20);
      }
    }
  }

  &.color_green {

    .text_container {
      @include persicon-gradient(20deg, $color-green-alpha, $color-green2-alpha);
    }
  }

  &.color_orange {

    .text_container {
      @include persicon-gradient(20deg, $color-orange-alpha, $color-orange2-alpha);
    }
  }

  @include breakpoint($bp-tablet) {

    > .inside {
      height: auto;
      padding-bottom: 0;
    }

    .text_container {
      @include span(6 of 12);
      @include pre(3 of 12);
      @include post(3 of 12);
      padding: 30px 40px 50px 30px;
      margin-top: 160px;
      margin-bottom: 160px;

      &:before,
      &:after {
        font-size: 34px;
      }

      &:before {
        top: -20px;
        right: -20px;
      }

      &:after {
        bottom: -20px;
        left: -20px;
      }
    }

    .headline,
    .subheadline {
      @include font(28, 36);
    }
  }

  @include breakpoint($bp-mobile) {
    .text_container {
      @include span(10 of 12);
      @include pre(1 of 12);
      @include post(1 of 12);
      padding: 18px 30px 60px 20px;

      .headline,
      .subheadline {
        @include font(24, 28);
      }
    }
  }
}