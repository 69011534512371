.news_latest_hero {
  .headline {
    margin: 0;
    font-family: $font-family-bold;

    a {
      color: $font-color-invert;
      text-decoration: none;
      
      &:hover {
        color: $font-color-base;
      }
    }
  }

  &.hero .text_container {
    a.readmore {
      &:hover {
        color: $font-color-base;
      }
    }
  }
}

.news_latest {
  position: relative;
  float: left;
  height: 0;
  width: 50%;
  padding-bottom: (50% / 2);
  background-size: cover;
  background-position: center;

  &:before {
    @include persicon-gradient($gradient-direction: 45deg, $gradient-color1: $color-orange, $gradient-color2: transparent);
    position: absolute;
    content: '\00a0';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .text_container {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
    padding: ($baseline * 2.25) ($grid-padding * 2);
    box-sizing: border-box;
    overflow: auto;
    z-index: 2;

    > .inside {
      display: table-cell;
      height: 100%;
      width: 100%;
    }

    .date,
    .category {
      display: inline-block;
      font-weight: bold;
    }

    .info {
      font-family: $font-family-bold;
      margin-bottom: ($baseline * .25);
    }

    .title {
      @include font(28, 32);
      margin: 0;
      min-height: 129px;

      a, a:link, a:visited {
        color: $font-color-invert;
        text-decoration: none;
      }
      
      a:hover {
        color: $font-color-base;
      }
    }

    .subtitle {
      display: none;
    }

    .readmore {
      display: none;
    }
  }

  @include breakpoint($bp-tablet) {
    width: 100%;
    height: auto;
    padding-bottom: 0;
    min-height: ($baseline * 10);

    .text_container {
      position: relative;
      padding: 30px ($grid-padding * 2);

      .title {
        @include font(24, 28);
        min-height: 0;
      }
    }
  }

  @include breakpoint($bp-large) {
    width: (100% / 3);
  }
  
  .site-hero & {
    width: 100%;
    float: none;
  }
}

.news_full_hero {
  .info {
    color: $font-color-invert;
    margin-bottom: ($baseline * .25);
    font-family: $font-family-bold;
  }

  &.no-bg {
    @include persicon-gradient($gradient-direction: 45deg, $gradient-color1: $color-orange, $gradient-color2: transparent);
  }

  @include breakpoint($bp-mobile) {
    .text_container {
      .headline {

      }
    }
  }
}

.news_full.news_advanced {
  .news_full_body {
    padding-bottom: $baseline;
  }
}

.mod_newsreader {
  .back {
    display: none;
  }
}

.news_full_body {
  padding: ($baseline * 2) 0;

  h2 {
    margin: 0 0 24px;
    padding: 0;
  }
  
  .info {
    color: $font-color-orange;
  }

  .text_container {
    @extend .container;

    > .inside {
      @extend .text_narrow;

      ul {
        @extend .styled_list;
      }
    }
  }
}

.news_full_advanced {
  .ce_list,
  .ce_table,
  .ce_markdown {
    @include container;
  }
}