.ce_sliderStart {
  position: relative;

  &.claim_medium {
    .content-slider {
      padding: 60px;
    }
  }

  //.slider-control {
  //  position: absolute;
  //  top: 0;
  //  width: 100%;
  //  z-index: 1;
  //}

  .text_container {
    position: relative;
    z-index: 200;
  }

  .slider-control {
    position: static;
    height: 0;
    padding: 0;
    margin: 0;
    display: block;
  }

  .slider-menu {
    position: absolute;
    top: auto;
    bottom: 16px;
    left: 0;
    height: 30px;
    width: 100%;
    text-align: center;
    z-index: 400;
    margin-left: 0;

    b {
      font-size: 42px;
      color: $bg-color-global;
      margin: 0 4px;
    }

    b.active {
      color: $btn-primary-bg;
    }
  }

  .slider-prev,
  .slider-next {
    position: absolute;
    overflow: hidden;
    top: 50%;
    margin-top: -21px;
    height: 42px;
    width: 42px;
    padding-left: 42px;
    z-index: 300;
    display: none;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 42px;
      line-height: 1;
      color: $font-color-invert;
      text-align: center;
      font-family: 'persicon';
    }

    .site-hero & {
      display: block;
    }
  }

  .slider-prev {
    left: 20px;
    @extend .icon-arrow-left;
  }

  .slider-next {
    right: 20px;
    @extend .icon-arrow-right;
  }

  @include breakpoint($bp-mobile) {
    .slider-prev,
    .slider-next {

      .site-hero & {
        display: none;
      }
    }
  }
}