.colorset {
  position: relative;

  > .inside {
    position: relative;
    z-index: 200;
  }

  &:before {
    position: absolute;
    content: '\00a0';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

[class*="teaser_"] {
  background-size: cover;

  > .inside > a {
    text-decoration: none;
  }

  .headline,
  .subheadline {
    @include font(24, 28);
    margin: 0;
    padding: 0;
    color: $font-color-base;
    text-decoration: none;
  }

  .headline {
    color: $font-color-invert;
  }
}

.teaser_square {
  position: relative;
  display: block;
  float: left;
  width: (100% / 3);
  height: 0;
  margin: 0;
  padding-bottom: (100% / 3);
  overflow: hidden;

  > .inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
  }

  .headline,
  .subheadline {
    @include font(24, 28);
  }

  .readmore {
    //transition: .5s;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    span {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 30px;
      right: 30px;
    }

    &:before {
      display: none;
    }
  }

  &:hover .readmore {

    span {
      opacity: 1;
      visibility: visible;
      color: $font-color-invert;
    }

    span:before {
      @extend .icon-arrow-right:before;
      font-family: 'persicon';
      font-size: 14px;
    }
  }

  &.ce_image {
    > .inside {
      padding: 0;
    }

    .image_container {
      padding: 0;
      margin: 0;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &.color_blue:before {
    @include persicon-gradient(to bottom, $color-blue2-alpha 30%, $color-blue-alpha 100%);
  }

  &.color_green:before {
    @include persicon-gradient(to bottom, $color-green2-alpha 30%, $color-green-alpha 100%);
  }

  &.color_orange:before {
    @include persicon-gradient(to bottom, $color-orange2-alpha 30%, $color-orange-alpha 100%);
  }

  @include breakpoint($bp-tablet) {
    width: 50%;
    float: left;
    padding-bottom: 50%;
  }

  @include breakpoint($bp-mobile) {
    .headline,
    .subheadline {
      @include font(16, 20);
    }

    > .inside {
      padding: 20px;
    }
  }
}

.teaser_medium {
  width: 50%;
  float: left;
  min-height: 600px;
  display: table;

  > .inside {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    padding: 60px;
  }

  &.ce_image {
    padding: 0 !important;
    min-height: 0;

    > .inside {
      padding: 0;
    }

    .image_container {
      margin: 0;
      padding: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &.contact_info {
    padding: 0 span(1 of 12);
    min-height: 0;

    > .inside {
      padding: $baseline $grid-padding $baseline 0;
    }

    .btn {
      margin-top: $baseline;
    }
  }

  &.color_blue:before {
    @include persicon-gradient(to bottom, $color-blue-alpha 30%, $color-blue2-alpha 100%);
  }

  &.color_green:before {
    @include persicon-gradient(to bottom, $color-green-alpha 30%, $color-green2-alpha 100%);
  }

  &.color_orange:before {
    @include persicon-gradient(to bottom, $color-orange-alpha 30%, $color-orange2-alpha 100%);
  }

  @include breakpoint($bp-mobile) {
    &.contact_info {
      padding: 0;
    }
  }
}

.teaser_giant,
.teaser_large {

  > .inside {
    @extend .container;
    padding-top: ($baseline * 3);
    padding-bottom: ($baseline * 6);
  }

  .headline_container {
    margin-bottom: 30px;
  }

  &.colorset {
    .text_container {
      color: $font-color-invert;
    }
  }

  &.color_blue {
    &:before {
      @include persicon-gradient(to bottom, $color-blue-alpha 60%, transparent 100%);
    }
  }

  &.color_green {
    &:before {
      @include persicon-gradient(to bottom, $color-green-alpha 60%, $gradient-color2: transparent 100%);
    }
  }

  &.color_orange {
    &:before {
      background: linear-gradient(to bottom, $color-orange2-alpha 50%, transparent 100%);
    }
  }

  .site-main & {

    .readmore {
      @extend .btn;
      margin-top: $baseline;
    }
  }
}

.teaser_giant {
  min-height: 700px;

  .headline_container {
    margin-left: span(1 of 12);
  }

  //.headline {
  //  padding-top: 60px;
  //}

  .headline,
  .subheadline {
    @include font(48, 58);
  }

  p.large {
    @include font(30, 42);
    font-family: $font-family-medium;
    margin-bottom: ($baseline * .75);
  }

  .text_container {
    @include span(10 of 12);
    @include pre(1 of 12);
    clear: none;

    .btn {
      margin-top: ($baseline * 1.5);
    }
  }

  .image_container {
    @include brackets($indent: false);
    @include span(2 of 12);
    @include pre(1 of 12);
    margin-top: ($baseline * 2);
    position: relative;

    & + .text_container {
      @include span(8 of 12);
      @include pre(0);
      @include last;
      margin-top: ($baseline * 3.5);
    }

    img {
      width: 100%;
      height: auto;
    }

    &:before {
      top: -35px !important;
      right: -30px !important;
    }

    &:after {
      bottom: -25px;
      left: -30px;
    }
  }

  @include breakpoint($bp-mobile) {
    min-height: 300px;

    > .inside {
      padding: 40px 0;
    }

    .headline,
    .subheadline {
      @include font(24, 28);
    }

    .headline_container {
      @include span(4 of 4);
      @include pre(0);
    }

    .image_container,
    .text_container {
    }

    .text_container {
      @include font(16, 24);
    }

    .image_container {
      @include span(1 of 4);
      @include pre(0);
      @include post(0);
      margin-top: ($baseline * .5);

      & + .text_container {
        @include span(3 of 4);
        @include last;
        @include pre(0);
        @include post(0);
        padding-left: 20px;
        margin-top: ($baseline * .75);
      }

      &:before {
        top: -20px !important;
        right: -10px !important;
      }

      &:after {
        bottom: -15px;
        left: -10px;
      }
    }

    p.large {
      @include font(16, 24);
    }
  }
}

.teaser_large {

  .headline {
    padding-top: 60px;
  }

  .headline_container {
    @include span(8 of 12);
    @include push(2 of 12);
  }

  .headline,
  .subheadline {
    @include font(48, 56);
  }

  p.large {
    @include font(30, 42);
  }

  .image_container {
    @include span(2 of 12);
    @include push(2 of 12);
    @include brackets;
    position: relative;

    & + .text_container {
      @include span(6 of 12);
      @include push(0 of 12);

      .headline,
      .subheadline {
        @include push(12 of 12);
        @include push(0 of 12);
      }
    }
  }

  .text_container {
    @include span(10 of 12);
    @include push(1 of 12);
  }
}

.teaser_medium {
  width: 50%;
  float: left;

  .headline,
  .subheadline {
    @include font(30, 36);
  }

  .readmore {
    @extend .btn;
    margin-top: $baseline;
  }

  @include breakpoint($bp-tablet) {
    display: block;
    min-height: 0;

    .headline,
    .subheadline {
      @include font(21, 28);
    }
  }

  @include breakpoint($bp-large) {

  }

  @include breakpoint($bp-mobile) {
    width: 100%;
    float: none;
    min-height: 0;
  }
}

.teaser_portrait {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: (100% / 2);
  //height: 0;
  //padding-bottom: (100% / 2);
  //float: left;

  > .inside {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    > a  {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      
      &:hover {
        background: rgba(0, 0, 0, .2);
      }
    }

    .text_container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      padding: 20px 30px;
    }
  }

  .headline,
  .subheadline {
    @include font(18, 24);
    font-family: $font-family-medium;
  }

  .subheadline {
    color: $font-color-invert;
  }

  .readmore {
    //transition: .5s;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;

    span {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }

    &:before {
      display: none;
    }

    &:hover {
      background: rgba(0, 0, 0, .2);
    }
  }

  //&:hover .readmore {
  //  opacity: 1;
  //  visibility: visible;
  //}

  &.ce_image {
    > .inside {
      padding: 0;
    }

    .image_container {
      padding: 0;
      margin: 0;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .text_container,
  &:before {
    //transition: $transition-duration;
    opacity: 1;
    visibility: visible;

    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  &.color_blue:before {
    @include persicon-gradient(to top, $color-blue-dark, transparent 30%);
  }

  &.color_green:before {
    //transition: $transition-duration;
    @include persicon-gradient(to top, $color-green, transparent 30%);
  }

  &.color_orange:before {
    //transition: $transition-duration;
    @include persicon-gradient(to top, $color-orange, transparent 30%);
  }

  @include breakpoint($bp-mobile) {
    .headline,
    .subheadline {
      @include font(18, 24);
    }
  }
}

.seminars_teaser {
  .item {

  }
}

.teaser_schulung,
.seminars_teaser .item {
  width: (100% / 3);
  float: left;

  &:nth-child(3n) {
    & + .item {
      clear: both;
    }
  }

  @include breakpoint($bp-mobile) {
    width: (100% / 2);
    float: left;

    &:nth-child(3n) {
      & + .item {
        clear: none;
      }
    }

    &:nth-child(2n) {
      & + .item {
        clear: both;
      }
    }
  }

  @include breakpoint($bp-small) {
    width: 100%;
    float: none;
    clear: both;
  }
}

.teaser_download {
  background: $bg-color-downloads;

  > .inside {
    @extend .container;
    padding-top: ($baseline * 2.5);
    padding-bottom: ($baseline * 3);
  }

  &.colorset {
    .text_container {
      color: $font-color-invert;
    }
  }

  .image_container {
    @include span(2 of 12);
    @include push(2 of 12);
    @include brackets($indent: false, $small: true);
    padding: 20px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    & + .text_container {
      @include span(7 of 12);
      @include last;
      margin-top: ($baseline * 1.125);

      .text {
        margin-top: ($baseline * .5);
      }
    }
  }
  
  .btn {
    margin-top: ($baseline * .5);
    background: $bg-color-content;
    color: $font-color-blue;
  }

  .headline {
    color: $font-color-base;
  }

  &.color_blue {
    background: $bg-color-downloads;
  }

  &.color_green {
    background: $color-green;
  }

  &.color_orange {
    background: $color-orange;
  }

  @include breakpoint($bp-tablet) {
    .image_container {
      @include span(2 of 12);
      @include push(0);

      & + .text_container {
        @include span(9 of 12);
        @include last;
      }
    }
  }
}