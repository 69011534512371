.ce_metamodels_frontendfilter {

  label {
    display: none;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-left: $grid-padding;
    margin-right: $grid-padding;
  }

  li {
    display: inline-block;

    a, a:link, a:visited {
      display: block;
      padding: ($baseline / 2) $grid-padding;
      color: $font-color-light;
      text-decoration: none;

      .active & {
        font-weight: bold;
        color: $color-orange;
      }
    }

    .count {
      display: none;
    }
  }
}

.features_teaser {

  .item {
    position: relative;
    height: 0;
    width: (100% / 2);
    padding-bottom: (100% / 3);
    float: left;
    background-size: cover;
    background-position: center center;
    //border-bottom: 1px solid $color-orange;
    //
    //&:nth-child(2n) {
    //  border-left: 1px solid $color-orange;
    //}

    .text_container {
      padding: ($grid-padding * 2);
    }

    .date {
      margin-right: $grid-padding;
    }

    .title a {
      display: block;
    }
  }

  .item.event {

    .text_container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: 800px;

      > .inside {
        position: relative;
        min-height: 100px;
        background: $bg-color-global;
        padding: ($baseline / 2) $grid-padding ($baseline / 2) 120px;
      }

      .category {
        display: none;
      }

      .date {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100px;
        padding: 20px;
        background: $color-orange;
        text-align: center;
        color: $font-color-invert;

        .year {
          display: none;
        }
      }

      .title,
      .subtitle {
        @include font(18, 21);
        margin: 0 0 ($baseline / 4) 0;
        padding: 0;
      }

      .title {
        color: $color-orange;

        a, a:link, a:visited {
          color: $color-orange;
          text-decoration: none;
        }
      }

      .subtitle {
        font-weight: normal;
      }

      .readmore {
        color: $font-color-invert;
        text-decoration: none;
        display: none;
      }
    }

    &.first,
    .feature_hero & {
      padding-bottom: (100% / 3 * 2);

      .text_container > .inside {
        background: transparent;
        padding-left: 140px;
        @include persicon-gradient($gradient-color1: $color-orange-alpha, $gradient-color2: $color-orange2-alpha);
      }

      .date {
        color: $font-color-base;
        background: $bg-color-global;
        width: 120px;

        .day {
          @include font(48, 56);
          font-weight: bold;
          display: block;
        }

        .month {
          font-weight: bold;
        }
      }

      .title,
      .subtitle {
        @include font(20, 23);
      }

      .title {
        color: $font-color-invert;

        a, a:link, a:visited {
          color: $font-color-invert;
        }
      }

      .readmore {
        display: inline-block;
      }
    }
  }

  .item.seminar {
    position: relative;
    float: left;
    width: 50%;
    height: 0;
    padding-bottom: (50% / 3);
    background-size: cover;
    background-position: center;

    .title,
    .subtitle {
      margin: 0;
      padding: 0;
    }
    
    .date {
      color: $color-orange;
    }

    .date,
    .category {
      font-weight: bold;
      display: inline-block;
    }

    .title {
      a, a:visited {
        color: $color-orange;
        text-decoration: none;
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: '\00a0';
      left: 0;
      bottom: 0;
      background: red;
    }

    &:before {
      width: 1px;
      height: 100%;
      background: linear-gradient(to top, $color_orange, transparent);
    }

    &:after {
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, $color_orange, transparent);
    }
  }

  .item.news {
    padding-bottom: (100% / 2);

    .text_container {
      @include persicon-gradient($gradient-direction: 45deg, $gradient-color1: $color-orange, $gradient-color2: transparent);
      position: absolute;
      display: table;
      width: 100%;
      height: 100%;

      > .inside {
        display: table-cell;
        height: 100%;
        width: 100%;
        vertical-align: bottom;
      }

      .date,
      .category {
        display: inline-block;
        font-weight: bold;
      }

      .date {
        color: $font-color-invert;
      }

      .title {
        @include font(36, 43);
        a, a:link, a:visited {
          color: $font-color-invert;
          text-decoration: none;
        }
      }

      .subtitle {
        display: none;
      }

      .readmore {
        display: none;
      }
    }
  }

  @include breakpoint($bp-mobile) {
    .item {
      width: 100%;
      float: none;
    }
  }
}

.feature_hero {
  .features_teaser {

    .item {
      width: 100% !important;
      padding-bottom: 50% !important;
      float: none;
    }
  }
}

.feature_detail_hero {
  .date {
    font-weight: bold;
  }

  .title {
    @include font(36, 43);
    margin: 0;
    color: $font-color-invert;
  }
}

.feature_detail_body {
  padding-top: ($baseline * 3);
  padding-bottom: ($baseline * 3);

  .headline_container,
  .text_container {
    @include span(8 of 12);
    @include push(2 of 12);
  }
  
  .title {
    color: $color-orange;
  }
}

.feature_detail_facts {
  background: $bg-color-alt;
  padding: $baseline 0;

  .label {
    font-weight: bold;

    .feature_event & {
      color: $color_orange;
    }
  }

  .text_container {
    @include span(8 of 12);
    @include push(2 of 12);
  }
}