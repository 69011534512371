.jobs {
  @include span(8 of 12);
  @include pre(2 of 12);
  @include post(2 of 12);

  @include breakpoint($bp-mobile) {
    @include span(4 of 4);
    @include pre(0);
    @include post(0);
  }
}

.jobs_list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .item {
    border-top: 1px solid $font-color-base;

    a, a:link, a:visited, a:active {
      display: block;
      padding: ($baseline * .25) 18px ($baseline * .25) 0;
      color: $font-color-base;
      text-decoration: none;
    }

    a:hover {
      color: $font-color-light;
    }

    a {
      position: relative;

      &:after {
        @extend .icon-arrow-right:before;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -12px;
        font-family: 'persicon';
        color: $color-green;
      }
    }
  }
}

.job_detail {
  .text_container {
    @extend .container;

    > .inside {
      @extend .text_narrow;
      padding-top: ($baseline * 2);
      padding-bottom: ($baseline * 2);
    }
  }

  ul {
    @extend .styled_list;
  }

  .job_detail_facts {
    padding: ($baseline * 2) $grid-padding;
    background: $bg-color-alt;
    
    .inside {
      overflow: hidden;
      @include span(8 of 12);
      @include pre(2 of 12);
      @include post(2 of 12);
      
      .label {
        font-family: $font-family-bold;
        color: $color-green;
        margin-right: $grid-padding;
      }

      .value {
        .item + .item {
          &:before {
            content: ', ';
          }
        }
      }
    }
  }

  .back {
    > .inside {
      @include span(8 of 12);
      @include pre(2 of 12);
      @include post(2 of 12);
      margin-bottom: $baseline;
    }
  }
}

.page-karriere {
  .mod_article.first {
    > .container {
      @include breakpoint($bp-small) {
        padding-top: 40px;
      }
    }
  }
}