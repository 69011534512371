.seminars_upcoming {
  background: linear-gradient(87deg, $bg-color-alt 0%, $bg-color-alt2 100%);

  .ce_metamodel_content {
    @extend .container;
  }
}

.seminars_home_leading {
  padding: ($baseline * 3) 0 ($baseline * 2);

  .item {
    @include span(9 of 12);
    @include last;

    .date {
      @include font(16, 20);
      font-family: $font-family-bold;
      font-weight: normal;
      color: $font-color-orange;
    }
    
    .category {
      @include font(24, 28);
      font-family: $font-family-bold;
    }
  }

  .headline_container {
    //@include span(3 of 12);
    float: left;
    width: span(3 of 12);
  }

  .title {
    @include font(24, 28);
    color: $font-color-orange;
    margin: 0 0 ($baseline * .75) 0;

    a {
      color: $font-color-orange;
      text-decoration: none;
    }
  }

  a.readmore {
    @extend .btn;
  }

  h2 {
    @include font(48, 56);
    @include brackets($color: $color-orange, $indent: false, $inline: true, $small: true);
    padding: 10px 30px 20px 30px;
    color: $font-color-base;

    & + .features_list {
      @include span(9 of 12);
      @include last;
    }
  }
}

.seminars_home_upcoming {
  clear: both;
  padding-bottom: $baseline;

  .item {
    @include font(20, 24);
    overflow: hidden;
    border-top: 1px solid $color-orange;
    padding: $baseline 0;
    font-family: $font-family-bold;

    .date {
      @include font(24, 24);
      @include span(3 of 12);
      color: $color-orange;
    }

    .category {
      @include span(3 of 12);
    }

    .title {
      @include font(24, 28);
      @include span(6 of 12);
      @include last;
      margin: 0;

      a {
        display: block;
        color: $font-color-base;
        text-decoration: none;

        &:hover {
          color: $font-color-orange;
        }
      }
    }

    .readmore {
      display: none;
    }
  }
}

.schulung_detail_body {
  > .inside {
    @extend .text_narrow;
  }

  .accordion .inside {
    @extend .text_narrow;
    overflow: hidden;
    padding-top: ($baseline * 2);
    padding-bottom: ($baseline * 2);

    li {
      border-bottom: 1px solid $color-blue2;

      ul {
        width: 100%;
        float: none;
        margin: ($baseline * .25) 0 0 0;
        padding: 0;
      }

      li {
        border-bottom: none;
      }
    }
  }

  .headline_container,
  .text_container {
    clear: both;
    overflow: hidden;
  }

  .headline_container {
    margin-top: $baseline;
  }

  .text_container {
    margin-bottom: ($baseline * 2);
  }
}

.seminars_teaser_wrapper {
  .seminars_teaser,
  .teaser_schulung {
    margin-bottom: ($baseline * 2);
  }

  .headline_container {
    h1, h2, h3 {
      @include font(28, 32);
      font-family: $font-family-bold;
    }
  }

  @include breakpoint($bp-mobile) {

    .headline_container {
      h1, h2, h3 {
        @include font(21, 24);
        font-family: $font-family-bold;
      }
    }
  }
}

.seminars_teaser,
.teaser_schulung {
  overflow: hidden;

  a {
    transition: $transition-duration;
    display: block;
    position: relative;
    padding: 20px;
    color: $color_orange;
    text-decoration: none;

    &:hover {
      background: $bg-color-alt;
    }

    &:before,
    &:after {
      position: absolute;
      content: '\00a0';
      left: 0;
      bottom: 0;
      background: red;
    }

    &:before {
      width: 1px;
      height: 100%;
      background: linear-gradient(to top, $color_orange, transparent);
    }

    &:after {
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, $color_orange, transparent);
    }
  }

  .category,
  .title {
    @include font(16, 21);
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .title {
    color: $font-color-base;
  }

  @include breakpoint($bp-mobile) {

  }
}