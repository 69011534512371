/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: rgba(17, 41, 62, 0.95);
}

#colorbox {
  outline: 0;
}

#cboxContent {
  margin-top: 40px;
  background: #000;
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  background: #fff;
}

#cboxTitle {
  position: absolute;
  top: -32px;
  left: 0;
  color: $font-color-invert;
  font-family: $font-family-bold;
}

#cboxCurrent {
  position: absolute;
  top: -32px;
  right: 0px;
  color: $font-color-invert;
}

#cboxLoadingGraphic {
  background: url("../images/loading.gif") no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: #fff;
}

#cboxClose,
#cboxPrevious,
#cboxNext {
  outline: none;
  text-indent: -99999px;

  &:before {
    font-family: 'persicon';
    text-indent: 0;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    color: $font-color-invert;
    height: 32px;
    width: 32px;
    font-size: 32px;
    line-height: 32px;
    opacity: .75;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

#cboxClose {
  &:before {
    left: 0;
    right: auto;
  }
}

#cboxPrevious {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;

  &:before {
    content: "\e902";
    left: 5px;
  }
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;

  &:before {
    content: "\e903";
    right: 5px;
  }
}

#cboxClose {
  position: fixed;
  top: 20px;
  right: 20px;
  display: block;
  width: 28px;
  height: 28px;

  &:before {
    content: "\e90a";
    font-size: 28px;
    line-height: 28px;
  }
}