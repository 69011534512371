.invisible {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.text_narrow {
  overflow: hidden;
  @include span(8 of 12);
  @include pre(2 of 12);
  @include post(2 of 12);

  @include breakpoint($bp-mobile) {
    @include span(12 of 12);
    @include pre(0 of 12);
    @include post(0 of 12);
  }

  //p, ol, ul, h1, h2, h3, h4, h5, dl, blockquote {
  //}

  //ul, ol {
  //  list-style-position: inside;
  //}

  li {

    ul {
      width: 100%;
      float: none;
      margin: ($baseline * .25) 0 0 0;
      padding: 0;
    }
  }
}