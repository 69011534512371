.ce_text {

  &.text_floating {
    @extend .container;

    .container & {
      padding: 0;
    }

    > .inside {
      @extend .text_narrow;
      position: relative;

      &:last-child {
        padding-top: 0;
      }
    }

    &.color_blue {
      h1,
      h2,
      h3,
      .headline,
      .subheadline {
        color: $font-color-blue;
      }
    }

    &.color_green {
      h1,
      h2,
      h3,
      .headline,
      .subheadline {
        color: $font-color-green;
      }
    }

    &.color_orange {
      h1,
      h2,
      h3,
      .headline,
      .subheadline {
        color: $font-color-orange;
      }
    }

    h1, h2, h3, h4 {
      margin: 0 0 $baseline 0;
    }

    h2 {
      @include font(36, 43);
    }

    h3 {
      margin-bottom: ($baseline * .5);
    }

    ul {
      @extend .styled_list;
      //margin-bottom: ($baseline * .5);
    }
  }

  p + hr {
    padding-top: ($baseline * 2);
    margin: ($baseline * -0.5) 0 ($baseline * 2);
  }

  @include breakpoint($bp-small) {
    &.text_floating {
      h1,
      h2 {
        @include font(24, 28);
        margin-bottom: ($baseline - 4px);
      }
    }
  }
}