@font-face {
  font-family: 'BrixSans-Regular';
  src: url('../fonts/BrixSansRegular.eot');
  src: url('../fonts/BrixSansRegular.eot?#iefix') format('embedded-opentype'), url('../fonts/BrixSansRegular.woff2') format('woff2'), url('../fonts/BrixSansRegular.woff') format('woff'), url('../fonts/BrixSansRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'BrixSans-Bold';
  src: url('../fonts/BrixSansBold.eot');
  src: url('../fonts/BrixSansBold.eot?#iefix') format('embedded-opentype'), url('../fonts/BrixSansBold.woff2') format('woff2'), url('../fonts/BrixSansBold.woff') format('woff'), url('../fonts/BrixSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'BrixSans-Medium';
  src: url('../fonts/BrixSansMedium.eot');
  src: url('../fonts/BrixSansMedium.eot?#iefix') format('embedded-opentype'), url('../fonts/BrixSansMedium.woff2') format('woff2'), url('../fonts/BrixSansMedium.woff') format('woff'), url('../fonts/BrixSansMedium.ttf') format('truetype');
}


@font-face {
  font-family: 'BrixSans-Light';
  src: url('../fonts/BrixSansLight.eot');
  src: url('../fonts/BrixSansLight.eot?#iefix') format('embedded-opentype'), url('../fonts/BrixSansLight.woff2') format('woff2'), url('../fonts/BrixSansLight.woff') format('woff'), url('../fonts/BrixSansLight.ttf') format('truetype');
}