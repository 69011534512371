
[class*="claim_"] {
  background-size: cover;
  background-position: center;

  > .inside > a {
    text-decoration: none;
  }

  .headline,
  .subheadline {
    @include font(24, 28);
    margin: 0;
    padding: 0;
    color: $font-color-base;
    text-decoration: none;
  }

  .headline {
    color: $font-color-invert;
  }
}

.claim_large {

  > .inside {
    @extend .container;
    text-align: center;
    padding-top: ($baseline * 4);
    padding-bottom: ($baseline * 5);
  }

  .text_container {
    @include brackets($color: $color-blue);
    display: inline-block;
    text-align: left;
    padding: 40px;
    width: auto;
    //max-width: 50%;

    .site-hero & {
      @include brackets($color: $bg-color-content);
    }
  }

  .headline,
  .subheadline {
    @include font(48, 58);
    padding: 0;
    margin: 0;
  }

  .headline {
    color: $color-blue;
  }

  //&.color_blue {
  //
  //  .headline {
  //    color: $color-blue;
  //  }
  //
  //  .text_container {
  //    @include brackets($color: $color-blue);
  //  }
  //}

  &.color_green {

    .headline {
      color: $color-green;
    }

    .text_container {
      @include brackets($color: $color-green);
    }
  }

  &.color_orange {

    .headline {
      color: $color-orange;
    }

    .text_container {
      @include brackets($color: $color-orange);
    }
  }

  .site-hero & {
    position: relative;
    height: auto;

    .headline {
      color: $font-color-invert;
    }

    .subheadline {
      color: $font-color-blue;
    }

    .text_container {
      @include brackets($color: $bg-color-content);
    }

    &.color_blue_light {
      .headline {
        color: $font-color-invert;
      }

      .subheadline {
        color: $font-color-blue;
      }

      &:before {
        @include persicon-gradient(to bottom, $color-blue-alpha, transparent);
      }
    }

    &.color_blue {
      .headline {
        color: $font-color-invert;
      }

      .subheadline {
        color: $font-color-blue;
      }

      &:before {
        @include persicon-gradient(to bottom, $color-blue-alpha, transparent);
      }
    }

    &.color_green {

      .headline {
        color: $font-color-invert;
      }

      .subheadline {
        color: $font-color-green;
      }

      .text_container {
        @include brackets($color: $bg-color-content);
      }

      &:before {
        @include persicon-gradient(to bottom, $color-green-alpha, transparent);
      }
    }

    &.color_orange {

      .headline {
        color: $font-color-invert;
      }

      .subheadline {
        color: $font-color-base;
      }

      .text_container {
        @include brackets($color: $bg-color-content);
      }

      &:before {
        @include persicon-gradient(to bottom, $color-orange-alpha, transparent);
      }
    }
  }

  @include breakpoint($bp-mobile) {
    > .inside {
      padding: ($baseline * 2) $grid-padding;
    }

    .headline,
    .subheadline {
      @include font(30, 28);
    }

    .text_container {
      @include brackets($small: true);
      padding: 24px;

      .site-hero & {
        @include brackets($small: true, $color: $bg-color-content);
      }
    }
  }
}

.claim_medium {
  width: 50%;
  float: left;
  min-height: 600px;
  z-index: 100;
  display: table;

  > .inside {
    display: table-cell;
    padding: 60px;
    vertical-align: middle;
    text-align: center;
  }
  
  .text_container {
    @include brackets($small: true);
    padding: 10px 20px 20px;
    display: inline-block;
    text-align: left;
  }

  .headline,
  .subheadline {
    @include font(36, 42);
  }

  &.color_blue {
    &:before {
      background: linear-gradient(to bottom, $color-blue-alpha 30%, transparent 100%);
    }
  }

  &.color_green {
    &:before {
      background: linear-gradient(to bottom, $color-green-alpha 30%,transparent 100%);
    }

    .headline {
      color: $font-color-green;
    }

    .subheadline {
      color: $font-color-invert;
    }
  }

  &.color_orange {
    &:before {
      @include persicon-gradient(to bottom, $color-orange-alpha 30%, transparent 100%);
    }
  }

  @include breakpoint($bp-mobile) {
    width: 100%;
    float: none;
    min-height: 0;

     > .inside {
       padding: 0 20px;
     }
  }
}