.ce_headline {
  &.text_floating {
    @extend .container;
    //margin-bottom: ($baseline * 2);

    .container & {
      padding: 0;
    }

    > .inside {
      @extend .text_narrow;
      position: relative;
      overflow: hidden;

      &:last-child {
        padding-top: 0;
      }
    }
  }

  &.last {
    h2, h3, h4 {
      margin-bottom: 0;
    }
  }
}