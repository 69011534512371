.styled_list {
  padding: 0;
  margin: 0 0 ($baseline * .5) 0;
  list-style-type: none;

  li {
    position: relative;
    padding: 7px 0 4px 18px;
    //margin-bottom: ($baseline * .25);

    ul {
      width: 100%;
      float: none;
      margin: ($baseline * .25) 0 0 0;
      padding: 0;
    }

    li {
      border-bottom: none;
    }

    &:before {
      position: absolute;
      content: '\2022';
      left: 0;
      top: 6px;
      color: $font-color-blue;
    }
  }
}

.site-main {
  ul {
    margin: 0 0 ($baseline * .25) 0;
  }
}