.mod_search {

  .formbody {
    background: $bg-color-content;
    border-bottom: 1px solid #000;
    display: inline-block;

    input[type="search"] {
      -webkit-appearance: textfield;
      border: 0;
      border-radius: 0;
      width: 260px;
      outline: none;

      height: ($baseline * 1.25);
      line-height: ($baseline * 1.25);
      color: $font-color-blue;
      padding: 0;
      text-indent: 0;
    }

    input[type="submit"] {
      display: none;
    }
  }

  .header {
    margin: $baseline 0;
  }
}