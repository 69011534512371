.site-main > .inside .ce_gallery {
  @extend .container;

  &:after {
    display: none !important;
  }

  > .inside {
    @extend .text_narrow;
  }
}

.ce_gallery {
  overflow: hidden;
  position: relative;
  padding-bottom: ($baseline * 2);
  margin-bottom: ($baseline * 2);

  .ce_text + & {
    margin-top: ($baseline * 3);
  }

  &:after {
    @include span(8 of 12);
    @include pre(2 of 12);
    @include post(2 of 12);
    position: absolute;
    content: '\00a0';
    left: 0;
    bottom: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, $border-color-gray, transparent);
  }

  &:last-child {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  ul,
  li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    float: left;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      width: auto;
    }
  }

  .image_container {
    background: $bg-color-content;
    margin: 1px;
    padding: 0;
  }

  li {
    float: left;
    width: span(4 of 12);

    &:nth-child(3n) + li {
      clear: both;
    }

    a:hover {

      img {
        opacity: .5;
      }
    }
  }

  //.cols_2 li {
  //  float: left;
  //  width: span(6 of 12);
  //
  //  &:nth-child(2n) + li {
  //    clear: both;
  //  }
  //}

  //.cols_3 li {
  //  width: span(4 of 12);
  //  float: left;
  //
  //  &:nth-child(3n) + li {
  //    clear: both;
  //  }
  //}
  //
  //.cols_4 li {
  //  width: span(3 of 12);
  //  float: left;
  //
  //  &:nth-child(4n) + li {
  //    clear: both;
  //  }
  //}

  @include breakpoint($bp-mobile) {
    li {
      width: span(6 of 12);

      &:nth-child(3n) + li {
        clear: none;
      }

      &:nth-child(2n) + li {
        clear: left;
      }
    }
  }
}