[class*="brick_"] {
  color: $font-color-invert;

  > .inside {
    padding: ($baseline * 2) ($grid-padding * 3);
    text-align: center;
  }

  .headline,
  .subheadline {
    color: $font-color-invert;
  }

  .text_container {
    margin-left: 0;
    margin-right: 0;
    display: inline-block;
    text-align: left;
  }

  .image_container {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  &.color_blue {
    background: $color-blue-dark;
    color: $font-color-invert;
  }

  &.color_blue_light {
    background: linear-gradient(to right, $color-blue, $color-blue2);
    color: $font-color-invert;
  }
  &.color_green {
    background: $color-green;
  }
  &.color_orange {
    background: $color-orange;
  }

  img {
    display: block;
    margin: 0 auto;
  }
  
  .home_info_text & {

    .ce_text {
      padding-top: ($baseline * 4);
      padding-bottom: ($baseline * 4);
    }

    &.color_blue {
      color: $font-color-invert;
      background: linear-gradient(to right, $color-blue, $color-blue2);

      .headline,
      .subheadline {
        color: $font-color-invert;
      }
    }
  }

  &.ce_sliderStart {
    .slider-next,
    .slider-prev {
      display: none !important;
    }

    .slider-control {
      display: none;
    }
  }

  @include breakpoint($bp-tablet) {
    width: 100% !important;
    float: none !important;
  }
}

.brick_1-3 {
  width: (100% / 3);
  float: left;

  .text_container {
    width: 100%;
  }
}

.brick_2-3 {
  width: (100% / 3 * 2);
  float: left;

  .text_container {
    max-width: 650px;
  }
}

.brick_100 {
  overflow: hidden;
  //padding-bottom: $baseline;

  h2 {
    @include font(32, 38);
  }

  > .inside {
    @extend .container;
  }

  .image_container.float_above {
    margin-bottom: ($baseline * 2);
  }

  .headline_container,
  .text_container {
    @include span(8 of 12);
    @include pre(2 of 12);
    text-align: left;
  }

  .competences & {
    .headline_container {
      margin-bottom: $baseline;
    }

    .headline,
    .subheadline {
      text-align: center;
    }
  }

  @include breakpoint($bp-mobile) {

    .headline_container,
    .text_container {
      @include span(4 of 4);
      @include pre(2 of 120);
    }

    .image_container {
      margin-left: 0;
      margin-right: 0;
    }
  }
}