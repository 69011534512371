a.readmore {
  color: $font-color-invert;
  font-weight: bold;

  &:before {
    content: '\e903';
    font-family: 'persicon';
    font-size: 14px;
    margin-right: 4px;
  }

  @include breakpoint($bp-mobile) {
    @include font(16, 20);
  }
}

.link_section {
  clear: both;

  a, a:link, a:visited {
    @include font(24, 42);
    transition: $transition-duration;
    display: block;
    padding: ($baseline * .75) $grid-padding;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(87deg, $bg-color-alt2, $bg-color-alt);
    text-decoration: none;
    color: $font-color-base;

    &:hover {
      color: $font-color-blue;
    }

    &:after {
      position: relative;
      top: 2px;
      content: '\e903';
      font-family: 'persicon';
      margin-left: 16px;
    }

  }

  @include breakpoint($bp-mobile) {
    a, a:link, a:visited {
      @include font(16, 24);
      padding: ($baseline * .5) $grid-padding;
    }
  }
}

.link_section_previous {
  @extend .link_section;
  width: 50%;
  float: left;
  clear: none;

  a {
    &:after {
      display: none;
    }

    &:before {
      content: '\e902';
      font-family: 'persicon';
    }
  }
}

.link_section_next {
  @extend .link_section;
  width: 50%;
  float: right;
  clear: none;
}

.back {
  margin-top: $baseline;

  a {
    @extend .btn;

    &:before {
      content: '\e902' !important;
    }
  }
}