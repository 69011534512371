.events_home {

  @include breakpoint($bp-mobile) {
    .item {
      width: 100% !important;
      float: none;
    }
  }
}

.mod_eventlist {
  .empty {
    padding: ($baseline * 2) ($grid-padding * 2);
  }
}

.mod_eventlist_upcoming {
  @extend .container;

  .headline_container {
    //@include span(3 of 12);
    float: left;
    width: span(3 of 12);
    margin-top: ($baseline * 1.5);

    h2 {
      @include font(48, 56);
      @include brackets($color: $color-orange, $indent: false, $inline: true, $small: true);
      font-family: $font-family-bold;
      padding: 10px 30px 20px 30px;
      color: $font-color-base;
    }
  }

  .site-hero & {
    @include persicon-color($color: $color-orange-alpha, $color2: $color-orange2-alpha);
    background-image: url('../images/bg_schulungen.jpg');
    background-position: center !important;
    background-size: cover !important;
    padding: ($baseline * 5) 0 !important;
    max-width: 100%;

    &:before {
      content: '\00a0';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    .headline_container {
      //@include span(3 of 12);
      float: left;
      width: span(4 of 12);
      @include post(1 of 12);

      h2 {
        @include font(48, 56);
        @include brackets($color: $color-orange, $indent: false, $inline: true, $small: true);
        padding: 10px 30px 20px 30px;
        color: $font-color-base;

        &:before,
        &:after {
          color: $font-color-invert;
        }
      }

      @include breakpoint($bp-tablet) {
        @include span(4 of 4);
      }
    }

    .event_upcoming.first {
      @include span(7 of 12);
      @include pre(0);
      @include last;

      .category {
        color: $font-color-base;
      }

      .date {
        color: $font-color-invert;
      }

      .title {
        a:link, a:visited, a:active, a:hover {
          color: $font-color-invert;
        }
      }

      @include breakpoint($bp-tablet) {
        @include span(4 of 4);
      }
    }
  }

  .empty {
    display: none;
  }

  @include breakpoint($bp-mobile) {
    .headline_container {
      width: span(4 of 4);
      margin-top: 0;

      h2 {
        @include font(36, 42);
        margin: 0;
        padding: 10px 20px 20px 20px;
      }
    }

    .site-hero & {
      padding: ($baseline * 2) 0 !important;
    }
  }

}

.event_upcoming {
  position: relative;
  @include font(20, 24);
  overflow: hidden;
  //border-top: 1px solid $color-orange;
  font-family: $font-family-bold;
  clear: both;

  &:before {
    content: '\00a0';
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, $color-orange, $bg-color-alt);
  }

  > a {
    &:link,
    &:visited,
    &:active {
      overflow: hidden;
      padding: $baseline 32px $baseline 0;
      display: block;
      color: $font-color-base;
      text-decoration: none;

      .title {
        color: $font-color-base;
      }
    }

    &:hover {
      text-decoration: none;

      .title {
        color: $font-color-orange;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 0;
      content: '\e903';
      font-family: 'persicon';
      margin-right: 6px;
      font-size: 24px;
      color: $font-color-orange;
    }
  }

  .date {
    @include font(24, 28);
    @include span(3 of 12);
    color: $color-orange;

    [class*="icon-"] {
      display: none;
    }
  }

  .category {
    @include font(24, 28);
    @include span(2 of 12);
  }

  .title {
    @include font(24, 28);
    @include span(7 of 12);
    @include last;
    margin: 0;

    a {
      display: block;
      color: $font-color-base;
      text-decoration: none;

      &:hover {
        color: $font-color-orange;
      }
    }
  }

  .readmore {
    display: none;
  }

  &.first {
    @include span(8 of 12);
    @include pre(1 of 12);
    @include last;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    clear: none;
    margin-top: 36px;
    margin-bottom: ($baseline * 3.25);

    &:before {
      display: none;
    }

    .date,
    .category,
    .title {
      display: block;
      width: 100%;
      float: none;
      margin-bottom: ($baseline * .125);
    }

    .date {
      @include font(16, 20);
      display: block;
      font-family: $font-family-bold;
      font-weight: normal;
      color: $font-color-orange;

      [class*="icon-"] {
        display: inline-block;
        margin-right: 6px;
      }
    }

    .category {
      @include font(24, 28);
      font-family: $font-family-bold;
    }

    a.btn {
      @extend .btn;

      &:after {
        display: none;
      }
    }

    .title {
      margin-bottom: ($baseline * .75);

      a {
        color: $color-orange;
      }
    }
  }

  @include breakpoint($bp-tablet) {

    > a, > a:link, > a:visited, > a:active {
      padding: ($baseline * .5) 32px ($baseline * .5) 0;
    }

    .category,
    .date {
      @include font(16, 20);
      float: left;
      width: auto;
    }

    .title {
      @include font(16, 20);
      width: 100%;
      float: none;
      padding: ($baseline * .125) 0 0 0;
      clear: both;
    }

    &.first {
      width: 100%;
      padding: 0;
      margin-bottom: ($baseline * 1);
    }
  }

  @include breakpoint($bp-mobile) {

    &.first {
      .title {
        @include font(24, 28);
      }
    }
  }
}

.event_teaser {
  position: relative;
  height: 0;
  width: (100% / 2);
  padding-bottom: (50% / 2);
  float: left;
  background: linear-gradient(45deg, $bg-color-alt, $bg-color-alt2);

  //&:before {
  //  content: '\00a0';
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  height: 100%;
  //  width: 100%;
  //  background: linear-gradient(45deg, $color-orange-alpha, $color-orange2-alpha);
  //  //z-index: 999;
  //}

  &.has_image {
    background-size: cover;
    background-position: center;
  }

  .text_container {
    padding: ($baseline * .75) ($grid-padding * 2);
  }

  .date {
    margin-right: $grid-padding;
  }

  a:link, a:visited, a:hover, a:active {
    display: block;
    color: $color-orange;
    text-decoration: none;
  }

  &.cal_1 {
    position: relative;
    float: left;
    width: 50%;
    height: 0;
    padding-bottom: (50% / 4);
    background-image: linear-gradient(-87deg, $bg-color-alt, $bg-color-alt2) !important;

    .title,
    .subtitle {
      @include font(20, 24);
      margin: ($baseline / 3.25) 0 0 0;
      padding: 0;
      font-family: $font-family-medium;
    }

    .date {
      color: $color-orange;
    }

    .date,
    .category {
      @include font(17, 21);
      font-family: $font-family-bold;
      display: inline-block;
    }

    .title {
      margin-top: ($baseline * .125);

      a:link, a:visited, a:active {
        color: $color-orange;
        text-decoration: none;
      }

      a:hover {
        color: $font-color-base;
      }
    }

    .readmore {
      display: none;
    }

    &:before,
    &:after {
      position: absolute;
      content: '\00a0';
      left: 0;
      bottom: 0;
      background: red;
    }

    &:before {
      width: 1px;
      height: 100%;
      background: linear-gradient(to top, $color_orange, transparent);
    }

    &:after {
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, $color_orange, transparent);
    }
  }

  &.cal_64 {

    .text_container {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: 800px;

      > .inside {
        position: relative;
        background: $bg-color-content !important;
        padding: ($baseline / 2) $grid-padding ($baseline / 2) 120px;
        min-height: ($baseline * 3.5);
      }

      .category {
        color: $font-color-orange;
      }

      .date {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100px;
        padding: ($baseline * .5) 20px;
        background: $color-orange;
        text-align: center;
        color: $font-color-invert;

        .year {
          display: none;
        }

        .day {
          @include font(32, 38);
          font-weight: bold;
          display: block;
        }

        .month {
          font-weight: bold;
        }
      }

      .title,
      .subtitle {
        @include font(18, 21);
        margin: 0 0 ($baseline / 4) 0;
        padding: 0;
      }

      .title {
        color: $color-orange;

        a, a:link, a:visited {
          color: $font-color-base;
          text-decoration: none;
        }

        a:hover {
          color: $font-color-orange;
        }
      }

      .subtitle {
        font-weight: normal;
      }

      .readmore {
        color: $font-color-invert;
        text-decoration: none;
        display: none;
      }
    }

    .events_teaser_home &:first-child,
    .feature_hero & {
      padding-bottom: (100% / 3 * 2);
      font-family: $font-family-bold;

      .text_container > .inside {
        background: transparent;
        padding-left: 140px;
        padding-top: ($baseline * .75);
        padding-bottom: ($baseline * .75);
        background: linear-gradient(to right, $color-orange-alpha, $color-orange2-alpha) !important;
      }

      .date {
        color: $font-color-base;
        background: $bg-color-global;
        width: 120px;
        font-family: $font-family-bold;

        .day {
          @include font(48, 56);
        }

        .year {
          display: inline-block;
        }
      }

      .category {
        color: $font-color-invert;
        margin-bottom: ($baseline * .5);
      }

      .title,
      .subtitle {
        @include font(20, 23);
      }

      .title {
        color: $font-color-base;

        a, a:link, a:visited {
          color: $font-color-base;
        }
      }

      .readmore {
        display: inline-block;

        a:link, a:visited, a:active {
          color: $font-color-invert;
        }
      }
    }

    //.events_teaser_home & {
    //  &:first-child {
    //    padding-bottom: (100% / 3 * 2);
    //
    //    .text_container > .inside {
    //      padding-left: 180px;
    //      background: linear-gradient(to right, $color-orange-alpha, $color-orange2-alpha) !important;
    //    }
    //
    //    .category {
    //      color: $font-color-invert;
    //    }
    //
    //    .date {
    //      width: 160px;
    //      background: $bg-color-content;
    //      color: $font-color-base;
    //    }
    //
    //    .title {
    //      font-family: $font-family-bold;
    //    }
    //  }
    //}
  }

  .site-hero & {
    float: none;
    display: block;
    width: 100%;
    padding-bottom: 50%;
  }

  @include breakpoint($bp-tablet) {
    float: none;
    height: auto;
    overflow: hidden;

    &.cal_1 {
      width: 100%;
      float: none;
      height: auto;
      padding-bottom: 0;
    }

    &.cal_64 {
      width: 100%;
      height: auto;
      padding-bottom: 0;

      .text_container {
        position: static;
        margin-top: ($baseline * 7);
      }
    }
  }

  @include breakpoint($bp-large) {
    &.cal_1 {
      width: (100% / 3) !important;
      padding-bottom: (100% / 3 / 4) !important;
    }

    &.cal_64 {
      width: (100% / 3) !important;
      padding-bottom: (100% / 3 / 2) !important;
    }
  }
}

.mod_eventreader {
  .back {
    display: none;
  }
}

.event_full {

  .event_full_hero {
    &.no-bg {
      background-image: url('../images/bg_events.jpg');
    }

    .date {
      font-family: $font-family-bold;
      color: $font-color-invert;
      margin-bottom: ($baseline * .25);
    }
  }

  .event_full_text {
    padding: ($baseline * 2) 0;

    h1 {
      margin-top: 0;
    }

    .text_container {
      @extend .container;

      > .inside {
        @extend .text_narrow;
      }
    }

    ul {
      @extend .styled_list;
      //margin-bottom: ($baseline * .5);
    }
  }

  .event_full_facts {
    padding: ($baseline) $grid-padding;
    background: $bg-color-alt;

    > .container > .inside {
      @extend .text_narrow;
    }
    
    .label {
      display: inline-block;
      font-family: $font-family-bold;
      color: $font-color-orange;
      margin-right: $grid-padding;
    }

    p {
      margin-bottom: 0;
    }
  }

  @include breakpoint($bp-mobile) {
    .event_full_facts {
      @include font(18, 28);
    }
  }
}