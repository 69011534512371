.vcard {
  background-image: url('../images/bg_feature_contact.png');

  &.teaser_large {

    > .inside {
      padding-top: ($baseline * 1.5);
      padding-bottom: ($baseline * 3);
    }

    .headline,
    .subheadline {
      @include font(36, 43);
    }
  }
}