input {
  @include font(16, 24);
}

input[type="text"] {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
}