.btn {
  &:link,
  &:visited {
    position: relative;
    display: inline-block;
    padding: 5px 32px;
    min-width: 230px;
    max-width: 100%;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    font-family: $font-family-medium;
    background: $btn-primary-bg;
    color: $font-color-invert;
  }

  &:hover {
    background: $btn-primary-bg-hover;
  }

  &:before {
    position: absolute;
    left: $grid-padding;
    content: '\e903';
    font-family: 'persicon';
    font-size: 14px;
    margin-right: 6px;
  }

  &.back {
    padding-left: 32px !important;

    &:before {
      content: '\e902';
    }
  }

  @include breakpoint($bp-mobile) {
    @include font(16, 20);
    min-width: 180px;
  }
}