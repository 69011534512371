.fact {
  background: $color-blue2;
  color: $font-color-invert;

  a {
    color: $font-color-invert;
  }

  &.color_blue {
    background: $color-green;
  }

  &.color_green {
    background: $color-green;
  }

  &.color_orange {
    background: $color-orange;
  }

  .text_container {
    padding: 20px;
    text-align: center;
  }
  
  .label {
    text-align: left;
  }

  .value {
    @include font(72, 72);
    @include brackets($small: true, $inline: true);
    display: inline-block;
    font-family: $font-family-bold;
    text-align: left;
    padding: 15px 30px;
  }

  @include breakpoint($bp-mobile) {
    .label {
      margin-bottom: 8px;
    }

    .value {
      @include font(48, 56);
      padding: 10px 20px;
    }
  }
}