@include bpLabel();

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background: $bg-color-global;
}

* {
  box-sizing: border-box;
}

.container {
  @include container();
  padding-left: 15px;
  padding-right: 15px;
}

.site-canvas {
  overflow: hidden;
  position: relative;
  min-height: 100%;
}

.site-wrapper,
.site-offcanvas {
  transition: $transition-duration-slow;
}

.site-wrapper {
  margin: 0 auto;
  max-width: 1920px;
  background: $bg-color-content;

  &:before {
    position: absolute;
    content: '\00a0';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .8);
    z-index: 0;
    transition: $transition-duration-slow;
    visibility: hidden;
    opacity: 0;
  }

  .back {
    @extend .container;
  }
}

.site-offcanvas {
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  margin-right: -320px;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  background: $bg-color-content;
  z-index: 998;
  transition: $transition-duration-slow;

  .nav_toggle {
    @include hide-text;
    float: right;
    border: 0;
    background: transparent;
    margin: ($baseline * .5) 6px;
    padding: 4px;
    display: block;
    position: relative;
    width: 32px;
    height: 38px;
    overflow: hidden;

    &:before {
      content: '\e90a';
      font-family: 'persicon';
      text-indent: 0;
      position: absolute;
      left: 0;
      top: 3px;
      height: 100%;
      width: 100%;
      font-size: 18px;
      line-height: 1;
    }
  }

  .offcanvas-search {
    position: relative;
    margin: ($baseline * .5) 60px 0 $grid-padding;

    &:after {
      @extend .icon-zoom:before;
      font-family: 'persicon';
      position: absolute;
      right: ($grid-padding * .5);
      top: 0;
      font-size: 18px;
    }

    h3 {
      display: none;
    }

    input {
      border: 0;
      display: block;
      width: 100%;
      border-bottom: 1px solid;
      line-height: $baseline * 1.125;
      outline: none;
    }
  }
}

.site-header {
  position: relative;

  > .inside {
    //@extend .container;
    position: relative;
    padding: 0 $grid-padding;

    &:after {
      content: " ";
      display: block;
      clear: both;
    }
  }

  .site-search {
    display: none;
  }
}

.site-logo {
  @include span(4 of 12);

  a {
    display: block;
    padding-top: 24px;
    padding-bottom: 16px;
  }

  img {
    display: block;
    max-height: 21px;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.nav-primary {
  @include span(8 of 12);
  @include last;
}

.site-hero {
  position: relative;
}

#career_home {
  > .block,
  > .claim_medium {
    height: 700px !important;
  }

  > .claim_medium {
    > .inside {
      padding-top: ($baseline * 3);
      padding-bottom: ($baseline * 3);
    }
  }

  .slider_jobs_home {
    width: 50%;
    float: left;
    min-height: 600px;
    //background: $color-green;
    background: linear-gradient(to bottom, $color-green 30%, $color-green2 100%);

    .ce_text {
      color: $font-color-invert;
    }

    h1,
    h2,
    h3,
    h4 {
      @include font(24, 28);
      color: $font-color-invert;
    }

    .slider-wrapper {
      padding-top: ($baseline * 7);
      padding-bottom: ($baseline * 7);
    }

    .text_container {
      padding: 0 62px;
      max-width: 480px;
    }

    .headline {
      margin: 0 0 ($baseline * 1.5);
    }

    .btn {
      margin-top: ($baseline * 1.5);
    }
  }

  @include breakpoint($bp-mobile) {

    .slider_jobs_home {
      width: 100%;
      float: none;
      min-height: 0;

      .slider-wrapper {
        padding-top: ($baseline * 3);
        padding-bottom: ($baseline * 3);
      }

      .text_container {
        padding: 0 ($grid-padding * 2);
      }
    }

    > .block,
    > .claim_medium {
      height: auto !important;
    }
  }
}

.site-footer {
  background: linear-gradient(87deg, $bg-color-alt2, $bg-color-alt);
}

.site-footer1 {
  @include font(16, 24);

  > .inside {
    @extend .container;
    position: relative;
    overflow: hidden;
    padding-top: ($baseline * 2);
    padding-bottom: ($baseline * 2);

    &:after {
      content: '\00a0';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      background: linear-gradient(-90deg, transparent 0%, #CFCFCF 50%, transparent 100%);
    }
  }

  h3 {
    display: none;
  }

  .footer-logo {
    @include span(2 of 12);
    height: 16px;

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
    }
  }

  .footer-contact {
    @include span(3 of 12);

    .headline,
    .subheadline {
      display: none;
    }

    //> .block {
    //  width: 50%;
    //  float: left;
    //  padding-right: $grid-padding;
    //}

    .ce_text {
      width: 100%;
    }
  }

  .footer-nav {
    @include span(2 of 12);
    @include post(2 of 12);
  }

  .footer-social {
    display: none;
    @include span(2 of 12);
    @include last;
  }
}

.site-footer2 {
  @include font(16, 24);
  padding: $baseline 0 ($baseline * 4) 0;
  overflow: hidden;
  color: $font-color-light;
  clear: both;

  > .inside {
    @extend .container;
  }

  .site-copyright {
    @include span(4 of 12);
  }

  .legal-nav {
    @include span(8 of 12);
    @include last;
  }
}

.page-kontakt {
  .ce_dlh_googlemaps {
    @include span(6 of 12);
  }

  @include breakpoint($bp-mobile) {

    .ce_dlh_googlemaps {
      @include span(12 of 12);
    }
  }
}

@include breakpoint($bp-tablet) {

  body.nav_active {
    overflow: hidden;
    padding-right: 15px;
    .site-offcanvas,
    .site-wrapper {
      transform: translateX(-320px);
    }

    .site-wrapper {
      position: absolute;

      &:before {
        visibility: visible;
        opacity: 1;
        z-index: 998;
      }
    }
  }

  .site-footer {
    .footer-logo {
      @include span(2 of 4);
      @include post(2 of 12);
      margin-bottom: $baseline;
    }

    .footer-contact {
      @include span(4 of 4);
      margin-bottom: $baseline;
    }

    .footer-nav,
    .footer-social {
      @include span(2 of 4);
    }

    .footer-social {
      @include last;
    }
  }
}

@include breakpoint($bp-mobile) {
  @include bpLabel('Mobile');

  .site-logo {
    @include span(2 of 4);
  }

  .nav-primary {
    @include span(2 of 4);
    @include last;
  }
}

@include breakpoint($bp-small) {
  .site-footer {
    .footer-contact {
      margin: 0;

      > .block {
        width: 100%;
        float: none;
        margin: 0 0 $baseline;
        padding: 0;
      }
    }

    .footer-nav,
    .footer-social {
      @include span(4 of 4);
    }

    .footer-nav {
      margin-bottom: $baseline;
    }
  }
}