@font-face {
  font-family: 'persicon';
  src:  url('../fonts/icons/persicon.eot?wp0yj1');
  src:  url('../fonts/icons/persicon.eot?wp0yj1#iefix') format('embedded-opentype'),
  url('../fonts/icons/persicon.ttf?wp0yj1') format('truetype'),
  url('../fonts/icons/persicon.woff?wp0yj1') format('woff'),
  url('../fonts/icons/persicon.svg?wp0yj1#persicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'persicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-hambuger:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-up:before {
  content: "\e909";
}
.icon-arrow-bl:before {
  content: "\e900";
}
.icon-arrow-br:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-tl:before {
  content: "\e904";
}
.icon-arrow-tr:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-zoom:before {
  content: "\e907";
}

