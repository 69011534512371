.ce_accordion {
  background: $bg-color-alt;
  overflow: hidden;
  border-bottom: 1px solid $bg-color-content;

  .toggler {
    background: $color-blue-dark-alpha;
    color: $font-color-blue;
    padding: ($baseline * .5) $grid-padding;
    font-family: $font-family-bold;
    cursor: pointer;
    //border-bottom: 1px solid $bg-color-alt;

    &:hover {
      color: lighten($font-color-blue, 10);
    }

    > .inside {
      @extend .container;
    }

    h3 {
      @include span(8 of 12);
      @include pre(2 of 12);
      @include post(2 of 12);
      @include font(24, 32);
      margin-top: 0;
      margin-bottom: 0;

      &:after {
        @extend .icon-arrow-right:before;
        position: relative;
        font-family: 'persicon';
        display: inline-block;
        margin-left: 21px;
        top: 2px;
      }
    }

    &.active h3:after {
      @extend .icon-arrow-down:before;
      margin-left: 12px;
    }
  }

  .ce_headline,
  .ce_text,
  .ce_list {
    @extend .container;
    padding-top: ($baseline * 2);
    padding-bottom: ($baseline * 1.5);

    > .inside {
      @extend .text_narrow;
    }

    ul, ol {
      margin-bottom: $baseline;
    }

    ul {
      @extend .styled_list;
    }

    li {
      border-bottom: 1px solid $font-color-blue;
      margin-bottom: 0 !important;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  ul {
    @extend .styled_list;

    ul {
      margin: 0;
      padding: 0;
    }
  }

  @include breakpoint($bp-mobile) {
    .toggler h3 {
      @include font(20, 26);
      @include span(12 of 12);
      @include pre(0);
    }
  }
}