
html {
  font-size: 10px;
}

body {
  @include font($font-size-base, $line-height-base);
  font-family: $font-family-base;
  color: $font-color-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.large {
  @include font(24, 32);
}

.intro {
  @include font(21, 32);
  font-family: $font-family-bold;
}

a {
  &:link,
  &:active,
  &:visited {
    color: $font-color-link;
  }
  
  &:hover {
    color: darken($font-color-link, 20);
  }
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

h1 {
  font-family: $font-family-bold;
  @include font(36, 43);
}

.headline,
.subheadline {
  font-family: $font-family-bold;
}

h2 {
  @include font(24, 28);
  font-weight: normal;
  font-family: $font-family-medium;

  p + &,
  ul + &,
  ol + & {
    margin: $baseline 0 ($baseline * .25) 0;
  }
}

h3, h4 {
  @include font(23, 28);
  font-family: $font-family-bold;
  margin: $baseline 0 ($baseline * .25) 0;
}

strong, b {
  font-family: $font-family-bold;
  font-weight: normal;
}

p {
  margin: 0 0 ($baseline * .75) 0;
  padding: 0;
}

hr {
  overflow: hidden;
  border: none;
  clear: both;
  height: 1px;

  &:after {
    content: '\00a0';
    position: absolute;
    width: 100%;
    left: 0;
    height: 1px;
    background: linear-gradient(to right, transparent, $border-color-gray, transparent);
  }
}

@include breakpoint($bp-tablet) {

}

@include breakpoint($bp-mobile) {
  body {
    @include font(18, 25);
  }

  h1, h2 {
    @include font(24, 28);
  }

  .large {
    @include font(24, 28);
  }

  .intro {
    @include font(18, 26);
  }
}