a, a:link, a:hover, a:visited, a:active {

  &[class^="pagination-"] {
    position: relative;
    background: linear-gradient(45deg, $color-orange, $color-orange2);
    float: left;
    width: 50%;
    height: 0;
    padding-bottom: (50% / 4);
    color: $font-color-invert;
    text-align: center;
    vertical-align: middle;

    span {
      @include font(24, 32);
      font-family: $font-family-bold;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -16px;
      width: 100%;
      text-align: center;

      &:before,
      &:after {
        position: relative;
        top: 3px;
      }
    }
    
    &:hover {
      background: lighten($color-orange, 10);
    }

    &.pagination-next {

      span {
        &:after {
          @extend .icon-arrow-right:before;
          margin-left: 6px;
          font-family: 'persicon';
        }
      }
    }

    &.pagination-previous {

      span {
        &:before {
          @extend .icon-arrow-left:before;
          margin-right: 6px;
          font-family: 'persicon';
        }
      }
    }

    @include breakpoint($bp-large) {
      width: (100% / 3);
      padding-bottom: (100% / 3 / 4);
    }

    @include breakpoint($bp-mobile) {
      padding-bottom: 20%;

      span {
        @include font(20, 20);
        margin-top: -10px;
      }
    }
  }
}