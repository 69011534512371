.site-header {
  position: relative;
}

body {
  &.nav_active {
    overflow-y: hidden;
  }
}

.nav-primary {
  //position: relative;

  .nav_close_wrapper {
    @include span(5 of 12);
    text-align: right;
    margin-top: $baseline;

    .nav_close {
      @include hide-text();
      display: inline-block !important;
      position: relative;
      width: 32px;
      height: 32px;
      margin-right: $grid-padding;
      border: 0;
      text-decoration: none;

      &:before {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend .icon-cross:before;
        text-indent: 0;
        font-family: 'persicon';
      }
    }
  }

  h2,
  .nav_toggle {
    display: none;
  }

  .level_1 {
    overflow: hidden;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      overflow: hidden;
      display: block;
      float: left;
      margin: 0;
      padding: 0;

      > span,
      > a {
        @include font(16, 20);
        display: block;
        margin: 28px 10px 0 10px;
        padding: 0 0 12px;
        color: $font-color-base;
        text-decoration: none;
      }

      > a {
        overflow: hidden;
        border-bottom: 3px solid transparent;

        &:hover {
          color: $color-blue-alpha;
        }
      }

      &.open {
        > .nav_overlay {
          opacity: 1;
          visibility: visible;
        }
      }

      &.active,
      &.trail {
        > a {
          color: $color-blue2;
          border-bottom-color: $color-blue2;

          body.nav_active & {
            border-bottom-color: transparent;
          }
        }
      }

      &.open {
        > a {
          color: $color-blue2;
          border-bottom-color: $color-blue2;
        }
      }

      &.search {
        float: right;

        a {
          position: relative;
          width: 18px;
          padding-left: 18px;

          &:before {
            @extend .icon-zoom:before;
            position: absolute;
            left: 0;
            text-indent: 0;
            font-family: 'persicon';
          }

          &.in:before {
            @extend .icon-cross:before;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
    }

    .nav_overlay {
      transition: $transition-duration;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      left: 0;
      width: 100%;
      height: calc(100vh - 63px);
      top: 63px;
      text-align: left;
      z-index: 800;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        height: 100%;
        content: '\00a0';
      }

      &:before {
        left: 0;
        width: span(5 of 12);
        background: $color-blue-dark-alpha;
      }

      &:after {
        right: 0;
        width: span(7 of 12);
        background: $color-blue-alpha;
      }

      > li {
        width: 50%;

        li {
          width: 100%;
        }
      }

      a {
        display: block;
        color: $font-color-invert;
      }

      .container {
        position: relative;
      }
    }
  }

  .level_2 {
    position: relative;
    padding-left: ($grid-padding * 2);
    color: $font-color-invert;
    list-style-type: none;
    clear: both;

    a, a:link, a:visited {
      @include font(24, 28);
      text-decoration: none;
      padding: ($baseline * .25) 0;
      font-family: $font-family-medium;

      &:after {
        @include font(24, 28);
        @extend .icon-arrow-right:before;
        position: relative;
        top: 4px;
        font-family: 'persicon';
        margin-left: 6px;
      }
    }

    a:hover {
      color: $color-blue2;
    }

    a.active {
      color: $color-blue2;
    }

    > li:hover > .item_slogan {
      opacity: 1;
      visibility: visible;
    }

    .item_slogan {
      transition-delay: .4s;
      transition: $transition-duration $transition-duration; // second is delay...
      position: absolute;
      display: block;
      opacity: 0;
      visibility: hidden;
      right: 0;
      top: 0;
      width: span(7 of 12);
      text-align: center;
      z-index: 999;

      .inside {
        @include brackets();
        display: inline-block;
        width: auto;
        max-width: 500px;
        text-align: left;
        margin: 10vh 60px;
        padding: 40px 20px 40px 40px;
      }

      .headline,
      .subheadline {
        font-family: $font-family-bold;
        @include font(32, 38);
        display: inline-block;
        margin: 0;
        padding: 0;
        clear: both;
        width: auto;
      }

      h2, h3 {
      }

      .headline {
        color: $font-color-invert;
      }

      .subheadline {
        color: $font-color-base;
      }
    }
  }

  .level_3 {
    display: none;
  }

  .active,
  .open,
  .trail {
    > ul.level_3 {
      display: block;
    }
  }

  li.search {
    position: relative;
    overflow: visible;
  }

  .site-search {
    width: span(8 of 12);
    //display: none;
    box-sizing: border-box;
    position: absolute;
    right: 42px;
    top: ($baseline * .75);
    background: $bg-color-content;

    input {
      border: 0;
      border-bottom: 1px solid #000;
      border-radius: 0;
      width: 100%;
      outline: none;
      padding-right: 54px;

      height: ($baseline * 1.25);
      line-height: ($baseline * 1.25);
      color: $font-color-blue;
    }

    .submit {
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      background: #fff;
    }

    @include breakpoint($bp-tablet) {
      display: none !important;
    }
  }
}

.nav-services {
  @include font(16, 20);
  background: $color-blue-dark;

  > .inside {
    @include container;

    > h2 {
      display: none;
    }
  }

  ul.level_1 {
    @include span(10 of 12);
    @include push(2 of 12);
    @include post(2 of 12);
    list-style-type: none;
    margin-bottom: 0;

    > li {
      position: relative;
      display: inline-block;
      margin-right: ($grid-padding * 2);

      > a {
        display: block;
        padding: ($baseline * .75) $grid-padding;
        color: $font-color-invert;
        text-decoration: none;

        &:hover {
          color: $font-color-blue;
        }
      }

      //&:first-child {
      //  margin-left: - $grid-padding;
      //}

      &.active {
        > a {
          color: $font-color-blue;
        }
      }

      &:hover {
        ul.level_2 {
          display: block;
        }
        
        > li > a {
          color: $font-color-blue;
        }
      }
      
      &.trail {
        > a {
          color: $font-color-blue;
        }
      }

      &.submenu {
        
        > a {
          position: relative;
          padding-right: 28px;

          &:after {
            @extend .icon-arrow-down:before;
            font-family: 'persicon';
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -8px;
            font-size: 12px;
          }
        }
      }
    }
  }

  ul.level_2 {
    list-style-type: none;
    background: $color-blue-dark;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0 0 ($baseline * .5);
    min-width: 100%;
    z-index: 900;

    > li {
      padding: 0;
      margin: 0;

      > a {
        display: block;
        padding: ($baseline * .25) $grid-padding;
        text-decoration: none;
        color: $font-color-invert;
        
        &:hover {
          color: $color-blue;
        }
      }
      
      &.active > a {
        color: $color-blue;
      }
    }
  }

  @include breakpoint($bp-tablet) {
    display: none;
  }
}

.nav-news {
  ul, li {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li,
  span,
  a {
    float: left;
    color: $font-color-light;
  }

  span, a {
    &:link,
    &:visited {
      color: $font-color-light;
      text-decoration: none;
    }

    &.trail,
    &.active {
      color: $font-color-orange;
    }
  }

  span {
    color: $color-orange;
  }

  .level_1 {
    padding-left: $grid-padding;

    span, a {
      font-family: $font-family-bold;
      display: block;
    }

    > li {
      & + li {
        position: relative;

        &:before {
          position: absolute;
          content: '\00a0';
          height: 100%;
          width: 1px;
          left: 0;
          top: 0;
          background: linear-gradient(to top, $color-orange, transparent);
        }
      }

      a,
      span {
        padding: ($baseline * .5) $grid-padding;
      }

      a:hover {
        color: $color-orange;
      }
    }
  }

  .level_2 {
    span, a {
      font-family: $font-family-light;
    }
  }
}

.footer-nav {

  ul, li {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }

  a:link, a:visited, a:active {
    color: $font-color-base;
    text-decoration: none;
  }

  a:hover {
    color: $font-color-link;
  }
}

.legal-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-block;
    margin: 0 ($grid-padding * 2) 0 0;
    padding: 0;
  }

  a {
    &:link,
    &:active,
    &:visited {
      padding: 0 $grid-padding 0 0;
      text-decoration: none;
      color: $font-color-light;
    }

    &:hover {
      color: $font-color-blue;
    }
  }
}

.nav-offcanvas {
  padding-top: ($baseline * 2);

  h3 {
    display: none;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .level_1 {
    ul {
      display: none;
    }
  }

  .level_1 {
    > li {

      > a {
        position: relative;
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
        margin-left: $grid-padding;
        display: block;
        color: $font-color-base;
        text-decoration: none;

        &:after {
          content: '\e903';
          font-family: 'persicon';
          position: absolute;
          right: ($grid-padding * 1.25);
          top: 50%;
          margin-top: -10px;
          font-size: 10px;
        }
      }

      &.submenu.open,
      &.submenu.active,
      &.submenu.trail {
        background: $color-blue2;

        a {
          color: $font-color-invert;
        }

        > ul {
          display: block;
        }
      }

      &.open,
      &.trail {

        > a {
          &:after {
            content: '\e908';
            font-size: 8px;
            right: 13px;
          }
        }
      }

      &.search {
        display: none;
      }
    }
  }

  .level_2 {
    @include font(18, 21);
    background: $color-blue-dark;
    padding: ($baseline * .25) 0;

    > li {

      > a {
        position: relative;
        display: block;
        padding: 10px 0;
        margin-left: $grid-padding;
        border-bottom: 1px solid $border-color-invert;
        color: $font-color-invert;
        font-family: $font-family-medium;

        &:after {
          content: '\e903';
          font-family: 'persicon';
          position: absolute;
          right: $grid-padding;
          top: 50%;
          margin-top: -8px;
          font-size: 10px;
          color: $font-color-invert;
        }
      }

      &:last-child {
        > a {
          border-bottom: 0;
        }
      }

      &.submenu.active,
      &.submenu.open,
      &.submenu.trail {

        > a {
          color: $font-color-blue;

          &:after {
            color: $font-color-blue;
          }
        }

        > ul {
          display: block;
        }
      }

      &.active {
        > a {
          color: $font-color-blue !important;
        }

        &.submenu {
          > a {
            border-bottom: 0;
          }
        }
      }

      &.open,
      &.trail {

        > a {
          &:after {
            content: '\e908';
            font-size: 8px;
            right: 13px;
            color: $font-color-blue;
          }
        }
      }
    }
  }

  .level_3 {
    padding: ($baseline * .25) 0;

    > li {
      > a {
        display: block;
        padding: ($baseline * .25) 0;
        margin-left: ($grid-padding * 2);

        &:before {
          content: "\e903";
          font-family: 'persicon';
          font-size: 9px;
          float: left;
          margin-right: 6px;
        }
      }
    }
  }

  .active,
  .trail {
    > ul {
      display: block;
    }
  }
}

@include breakpoint($bp-tablet) {
  .site-header {
    .nav-primary {

      .nav_toggle,
      .nav_toggle:active {
        background: transparent;
        overflow: hidden;
        border-radius: 0;
        text-indent: 110%;
        transition-duration: .6s;
        position: absolute;
        display: block;
        border-bottom: 4px solid;
        color: $font-color-base;
        outline: none;

        top: 26px;
        right: gutter(20px);
        height: 20px;
        width: 24px;

        padding: 0;
        margin: 0;
        z-index: 900;

        .small & {
          top: 14px;
        }

        .nav-active & {
          color: $font-color-base;
          border-color: $font-color-base;
          position: fixed;

          &:before, &:after {
            color: $font-color-base;
            border-color: $font-color-base;
          }
        }

        &:before,
        &:after {
          transition-duration: .3s;
          position: absolute;
          content: '\00a0';
          display: block;
          width: 100%;
          height: 0;
          border-color: $font-color-base;
          color: $font-color-base;
        }

        &:before {
          top: 0;
          border-bottom: 4px solid;
        }

        &:after {
          top: 50%;
          border-bottom: 4px solid;
        }
      }

      .level_1 {
        display: none;
      }
    }
  }
}