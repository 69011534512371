// Mixins
// -------------------------------------------------------

@mixin bpLabel($bpLabel: 'Default') {

  @if $showBpLabel == true {
    body:before {
      content: 'Breakpoint: ' $bpLabel;
      position: fixed;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 5px 10px;
      background: $color-green;
      color: #fff;
      z-index: 999;
    }
  }
}

@mixin hide-text() {
  text-indent: 150%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin font($font-size: $font-size-base, $lh: $line-height-base) {
  line-height: ($lh / 10) + rem;
  font-size: ($font-size/10) + rem;
}

@mixin persicon-gradient($gradient-direction: 20deg, $gradient-color1: $color-blue, $gradient-color2: $color-blue2) {
  background: linear-gradient($gradient-direction, $gradient-color1, $gradient-color2);
}

@mixin persicon-color($color: 'blue', $color2: 'transparent') {

  &:before {
    @include persicon-gradient(to bottom, $color, $color2);
  }
}

@mixin brackets($color: $bg-color-content, $indent: false, $inline: false, $small: false) {
  position: relative;

  @if $inline == true {
    display: inline-block;
    width: auto;
  }

  &:before,
  &:after {
    content: '\00a0';
    position: absolute;
    //width: 46px;
    //height: 46px;
    font-size: 60px;
    line-height: 1;
    font-family: 'persicon';
  }

  &:before {
    content: '\e905';
    color: $color;
  }

  &:after {
    content: '\e900';
    color: $color;
  }

  @include breakpoint($bp-mobile) {

    &:before,
    &:after {
      font-size: 32px;
    }
  }

  @if $indent == true {

    &:before {
      top: -30px;
      right: 30px;
      //border-top: 15px solid $color;
      //border-right: 15px solid $color;
      content: '\e905';
      color: $color;
    }

    &:after {
      bottom: 50px;
      left: -30px;
      //border-bottom: 15px solid $color;
      //border-left: 15px solid $color;
      content: '\e900';
      color: $color;
    }
  } @else {

    &:before {
      top: 0;
      right: 0;
      //border-top: 15px solid $color;
      //border-right: 15px solid $color;
    }

    &:after {
      bottom: 0;
      left: 0;
      //border-bottom: 15px solid $color;
      //border-left: 15px solid $color;
    }

    @include breakpoint($bp-mobile) {

    }
  }

  @if $small == true {

    &:before,
    &:after {
      font-size: 32px;
      //content: '\00a0';
      //position: absolute;
      //width: 25px;
      //height: 25px;
    }

    &:before {
      top: 0;
      right: 0;
      //border-top: 8px solid $color;
      //border-right: 8px solid $color;
    }

    &:after {
      bottom: 0;
      left: 0;
      //border-bottom: 8px solid $color;
      //border-left: 8px solid $color;
    }

    @include breakpoint($bp-mobile) {

      &:before,
      &:after {
        font-size: 24px;
      }
    }
  }
}