@import "fonts";
@import "../../bower_components/susy/sass/susy";
@import "../../bower_components/breakpoint-sass/stylesheets/breakpoint";

// General
// -------------------------------------------------------
$showBpLabel: false; // Breakpoint Label

// Colors
// -------------------------------------------------------

$bg-color-global: #ededed;
$bg-color-content: #fff;
$bg-color-alt: #F3F3F3;
$bg-color-alt2: #FAFAFA;

$bg-color-downloads: #009EE3;

$font-color-base: #242F38;

$font-color-invert: #fff;
$font-color-light: #A1A1A1;

$font-color-link: #00AAD2;
$font-color-link-hover: #00AAD2;

$font-color-blue: #009EE3;
$font-color-green: #A3FF38;
$font-color-orange: #F5A623;

// Borders

$border-color: rgba(17, 42, 62, .3);
$border-color-gray: #adadad;
$border-color-invert: rgba(255, 255, 255, .5);

// Colorset

$color-green: #067345;
$color-green2: #69A648;

$color-green-alpha: rgba(12, 115, 69, 0.92);
$color-green2-alpha: rgba(105, 166, 72, .92);

$color-blue: rgb(54, 92, 151);
$color-blue2: rgb(22, 167, 235);

$color-blue-alpha: rgba(4, 103, 173, 0.94);
$color-blue2-alpha: rgba(22, 167, 235, .92);

$color-blue-dark: #112A3E;
$color-blue-dark2: #112A3E;

$color-blue-dark-alpha: rgba(17, 42, 62, .92);
$color-blue-dark2-alpha: #112A3E;

$color-orange: rgb(234, 147, 63);
$color-orange2: rgb(232, 185, 84);

$color-orange-alpha: rgba(234, 147, 63, .92);
$color-orange2-alpha: rgba(245, 166, 35, 0.92);

// Buttons

$btn-primary-bg: #009EE3;
$btn-primary-bg-hover: lighten(#009EE3, 20);

// Grid
// -------------------------------------------------------

$susy: (
        columns: 12,
        column-width: 70px,
        gutters: .20,
        gutter-position: inside,
);

// Font
// -------------------------------------------------------

// Font sizes
$font-size-base: 20;
$line-height-base: 28;
$baseline: 28px;
$grid-padding: 15px;

// Font Families
$font-family-base: 'BrixSans-Regular', sans-serif;
$font-family-bold: 'BrixSans-Bold', sans-serif;
$font-family-medium: 'BrixSans-Medium', sans-serif;
$font-family-light: 'BrixSans-Light', sans-serif;
$font-family-alt: serif;

$padding-small: 10px;
$padding-base: 15px;
$padding-medium: 30px;
$padding-large: 60px;

$transition-duration: .1s;
$transition-duration-slow: .5s;

// Brealpoints
// -------------------------------------------------------
$bp-large: 1600px;
$bp-tablet: max-width 980px;
$bp-mobile: max-width 600px;
$bp-small: max-width 380px;